@font-face {
  font-family: "azad";
  src: local("azad"), url(./assets/CenturyGothic.ttf) format("opentype");
}

.option:hover {
  background-color: #ad171a !important;
}

.hover_text:hover {
  color: #f27820 !important;
}

.link_hover:hover svg {
  --tw-translate-x: 0.3rem /* 8px */;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-timing-function: linear;
}

body {
  color: black;
  position: relative;
  font-family: azad;
  color: #604b3b;
}

.custom_badge {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 2px 0;
  background: #ad171a;
  color: white;
  -webkit-clip-path: polygon(0 0, 72% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 72% 0, 100% 100%, 0% 100%);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
}

.image:hover .overlay {
  opacity: 1;
}

/* Global CSS or a module CSS file */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.transition-slide-in {
  animation: slideIn 0.3s ease-out;
}

.transition-slide-out {
  animation: slideOut 0.3s ease-in;
}

/* .hidden {
  pointer-events: none;
} */
